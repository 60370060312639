exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-pr-en-perfect-print-js": () => import("./../../../src/pages/pr/en/perfect_print.js" /* webpackChunkName: "component---src-pages-pr-en-perfect-print-js" */),
  "component---src-pages-pr-es-perfect-print-js": () => import("./../../../src/pages/pr/es/perfect_print.js" /* webpackChunkName: "component---src-pages-pr-es-perfect-print-js" */),
  "component---src-pages-pr-ja-a-4-paper-js": () => import("./../../../src/pages/pr/ja/a4_paper.js" /* webpackChunkName: "component---src-pages-pr-ja-a-4-paper-js" */),
  "component---src-pages-pr-ja-perfect-print-js": () => import("./../../../src/pages/pr/ja/perfect_print.js" /* webpackChunkName: "component---src-pages-pr-ja-perfect-print-js" */),
  "component---src-pages-pr-pt-prefect-print-js": () => import("./../../../src/pages/pr/pt/prefect_print.js" /* webpackChunkName: "component---src-pages-pr-pt-prefect-print-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy_policy_en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-term-en-js": () => import("./../../../src/pages/term_en.js" /* webpackChunkName: "component---src-pages-term-en-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */),
  "component---src-pages-tools-file-upload-js": () => import("./../../../src/pages/tools/file_upload.js" /* webpackChunkName: "component---src-pages-tools-file-upload-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-tag-index-js": () => import("./../../../src/templates/tag_index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */)
}

